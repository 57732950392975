<template>
    <div class="flex flex-column justify-content-center align-items-center" style="height: 500px; width: 100%">
        <div style="font-size:80px">403</div>
        <div>Forbidden</div>
    </div>
</template>
  
<script>

export default {
    data() {
        return {};
    },

    mounted() { },
    methods: {
    },
};
</script>
  
<style scoped></style>